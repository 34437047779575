import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'
import { theme } from '../../styles'
import { Layout, Wrapper, SEO, Articles } from '../../components'
import website from '../../../config/website'

const Hero = styled.div`
  background-color: ${theme.colors.white};
  padding-top: ${theme.spacing(24)};
  padding-bottom: ${theme.spacing(6)};
  padding: ${theme.spacing(16)} ${theme.spacing(3)} ${theme.spacing(6)};
  max-width: ${theme.maxWidth};
  margin: 0 auto;
  text-align: center;
`

const Headline = styled.h2`
  font-size: 4rem;
  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 3.5rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 3rem;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    font-size: 2.5rem;
  }
`

const SubHeadline = styled.p`
  font-size: 3.5rem;
  @media (max-width: ${theme.breakpoints.ms}) {
    font-size: 2.9rem;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 2.4rem;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    font-size: 2.2rem;
  }
`
const PageWrapper = Wrapper.withComponent('main')

const Content = styled.div`
  max-width: ${theme.maxWidthText};
  margin: ${theme.spacing(6)} auto 0;
  padding-bottom: ${theme.spacing(6)};
  padding: 6rem 0;
  @media (max-width: ${theme.breakpoints.l}) {
  }
  @media (max-width: ${theme.breakpoints.m}) {
    padding: 4rem 0;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    padding: 2rem 0;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
  }
`

function ThankYouPage({ data: { posts }, location }) {
  return (
    <Layout customSEO>
      <Amplitude
        eventProperties={{
          scope: ['form', 'success'],
          title: 'Gracias por ponerte en contacto con nosotros',
          url: location.pathname,
        }}
      >
        <LogOnMount eventType="page view" />
        <SEO
          title={`Gracias por ponerte en contacto con nosotros | ${website.titleAlt}`}
          pathname={location.pathname}
          desc="El formulario se ha enviado correctamente"
        />
        <Hero>
          <Headline>Contacta con nosotros</Headline>
          <SubHeadline>Gracias por ponerte en contacto con nosotros</SubHeadline>
        </Hero>
        <PageWrapper>
          <Content>
            <p>
              Nos pondremos en contacto contigo en breve a través de la dirección de correo
              electrónico que nos has facilitado.
            </p>
            {posts && (
              <React.Fragment>
                <p>Quizás pueda interesarte alguna de estas publicaciones recientes:</p>
                <Articles posts={posts.edges} />
              </React.Fragment>
            )}
          </Content>
        </PageWrapper>
      </Amplitude>
    </Layout>
  )
}

export default ThankYouPage

ThankYouPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    posts: PropTypes.object,
  }).isRequired,
}

export const pageQuery = graphql`
  query ThankYouPagePosts {
    posts: allPrismicPost(limit: 3, sort: { fields: [data___date], order: DESC }) {
      edges {
        node {
          uid
          type
          data {
            title {
              text
            }
            date(formatString: "DD/MM/YYYY")
            featured_image {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 720, maxHeight: 500, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    type
                    slugs
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
